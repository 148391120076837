<template>
  <div id="petwishlist-view" class="mt-2">
    <div class="breadcrumbs">
      <div class="container">
        <ul class="items">
          <li class="item home" style="float: inline-start !important;">
            <a href="#" title="wizard products" @click="goBack()">
              <i class="fa fa-arrow-left"></i> BACK TO PRODUCTS
            </a>
          </li>
          <!-- <li class="item product">
            Apple Iphone X 512Gb White 4GB RAM </li> -->
        </ul>
      </div>
    </div>
    <p v-if="imageshow">
      <span class="d-flex justify-content-center"
        ><img
          class="img-fluid text-center"
          src="@/assets/site/images/Group1.svg"
          alt=""
      /></span>
    </p>
    <div class="container-fluid" v-else>
      <center>
        <div class="findgft">
          <h1 class="display-1 mb-0" style="font-weight: 400;">
            <span>
              <img
                class="giftbox-img mr-2"
                src="@/assets/site/images/gift-box-left.svg"
                alt=""/></span
            >PET WISHLIST
            <span>
              <img
                class="giftbox-img ml-2"
                src="@/assets/site/images/gift-box-right.svg"
                alt=""
            /></span>
          </h1>
        </div>
      </center>
      <div class="product-slider product-slider-1">
        <div
          id="sm_filterproducts_1610096371335085279"
          class="products-grid mt-4"
        >
          <div class="row">
            <div
              class="col-md-3"
              v-for="(wishlistproduct, key) in wishlistproducts"
            >
              <div class="owl-item active wizard-box">
                <div class="item product product-item">
                  <div class="item-inner clearfix">
                    <div class="box-image">
                      <div class="label-sale pull-right">
                        <b-button
                          size="sm"
                          variant="outline-danger"
                          @click="deleteWishlist(wishlistproduct)"
                          class="fa fa-trash"
                          v-b-popover.hover.top="'Delete'"
                        ></b-button>
                      </div>
                      <a
                        @click="ViewProduct(wishlistproduct.product_decrypt)"
                        class="product photo product-item-photo"
                        tabindex="-1"
                      >
                        <span
                          class="product-image-container product-image-container-2281"
                          style="width: 300px;"
                        >
                          <span
                            class="product-image-wrapper"
                            style="padding-bottom: 100%;"
                          >
                            <span
                              v-if="wishlistproduct.product_decrypt.imageurl"
                            >
                              <img
                                class="wizard-image-photo lazyload"
                                :src="wishlistproduct.product_decrypt.imageurl"
                                data-src=""
                                loading="lazy"
                                max-width="300"
                                max-height="300"
                                alt="Fuhlen Mouse for Gamer Mutil Size And Color"
                              />
                            </span>
                            <span v-else>
                              <img
                                src="@/assets/site/images/noimage.jpeg"
                                alt=""
                                aria-labelledby="labelledby1611137713287"
                                class="fotorama__img magnify-opaque magnify-opaque single_product"
                                aria-hidden="false"
                              />
                            </span>
                          </span>
                        </span>
                      </a>
                      <div class="bottom-action my-3">
                        <!-- <a href="#" data-post="" @click="savetoFavourite(product)"
                            class="action towishlist btn-action link-wishlist" data-action="add-to-favourite"
                            title="Add to Favourite">
                            <span>Add to Favourite</span>
                          </a>
                         <a href="#" data-post="" @click="savetoWishlist(product)"
                            class="action tocart primary btn-action btn-cart" data-action="add-to-wishlist"
                            title="Add to Wish List">
                            <span>Add to Wish List</span>
                          </a>
                          <a href="#" class="action tocompare btn-action link-compare" @click="compareProduct(product)"
                            data-post="" title="Add to Compare">
                            <span>Add to Compare</span>
                          </a> -->

                        <a
                          href="#"
                          class="action quickview-handler sm_quickview_handler"
                          title="Quick View"
                          @click="ViewProduct(wishlistproduct.product_decrypt)"
                          ><span>Quick View</span></a
                        >
                      </div>
                    </div>
                    <div class="product details product-item-details box-info">
                      <h2 class="product-item-name">
                        <a class="product-item-link">
                          {{ wishlistproduct.product_decrypt.productname }}
                        </a>
                      </h2>

                      <div
                        class="price-box price-final_price"
                        data-role="priceBox"
                        data-product-id="2280"
                        data-price-box="product-id-2280"
                      >
                        <span
                          class="price-container price-final_price tax weee"
                        >
                          <span
                            id="product-price-2280"
                            data-price-amount="450"
                            data-price-type="finalPrice"
                            class="price-wrapper "
                            ><span class="price"
                              >{{
                                wishlistproduct.product_decrypt.price[
                                  "@currency"
                                ]
                              }}
                              {{
                                wishlistproduct.product_decrypt.price["#text"]
                              }}</span
                            ></span
                          >
                        </span>
                      </div>
                      <div class="product-reviews-summary short">
                        <div class="rating-summary">
                          <span class="label"><span>Rating:</span></span>
                          <div
                            class="rating-result"
                            id="rating-result_2280"
                            title="87%"
                          >
                            <span style="width: 87%;"><span>87%</span></span>
                          </div>
                        </div>
                        <div class="reviews-actions">
                          <a class="action view">
                            &nbsp;<span>Review </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <single-product
        v-if="!productShow"
        v-bind:gender="gender"
      ></single-product>
    </div>
  </div>
</template>
<script>
export default {
  name: "PetishlistView",
  data() {
    return {
      loader: false,
      errors: [],
      pages: [],
      wishlistproducts: [],
      imageshow: false,
      wishlist_status: null,
    };
  },
  created() {
    this.getWishlistProducts();
  },
  methods: {
    getWishlistProducts() {
      this.errors = [];
      //this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var user_id = userdata_array.user.id;
      //  var api_token = userdata_array.token;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url =
        process.env.VUE_APP_URL + "customer/userwishlist/getwishlistProducts";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          wishlist_type: "Pet",
          user_id: user_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.wishlistproducts = data.wishlist;
          this.wishlist_status = data.wishlist_status;
          if (this.wishlistproducts.length == 0) {
            this.imageshow = true;
          }
        });
    },

    deleteWishlist(wishlistproduct) {
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.user.api_token;
      var user_id = userdata_array.user.id;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url =
        process.env.VUE_APP_URL + "customer/userwishlist/deleteProduct";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          user_id: user_id,
          product_id: wishlistproduct.product_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            this.loader = false;
            this.getWishlistProducts();
          } else {
            this.loader = false;
            this.$swal.fire("Error!", data.message, "error");
          }
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    changeStatus(wishlist_status) {
      this.errors = [];
      //this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      var user_id = userdata_array.user.id;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url = process.env.VUE_APP_URL + "customer/changeWishlistStatus";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          user_id: user_id,
          wishlist_status: wishlist_status,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            this.$swal.fire("Good to Go", data.message, "success");
          } else {
            this.$swal.fire("Error!", data.message, "error");
          }
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    ViewProduct(wishlistproduct) {
      const id = Math.random()
        .toString(36)
        .slice(2);
      this.$router.push({
        path: "/single-product/product/" + id,
        query: {
          singleproduct: wishlistproduct,
          // allProducts_items: this.products,
          // allProducts_suggested_items: this.suggested_products ,
          // selected_friend: selected_friend,
          // clicked_wizarddata:this.wizard_data.wizard_details,
        },
      });
    },
    deleteWishlist(wishlistproduct, index) {
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.user.api_token;
      var user_id = userdata_array.user.id;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url =
        process.env.VUE_APP_URL + "customer/userwishlist/deleteProduct";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          user_id: user_id,
          product_id: wishlistproduct.productId,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            this.loader = false;
            this.$delete(this.wishlistproducts, index);
            this.$swal.fire("Good to Go", data.message, "success");
          } else {
            this.loader = false;
            this.$swal.fire("Error!", data.message, "error");
          }
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
  },
};
</script>
